.success {
    margin: 10px 0 0 10px;
    color: #208938;
    text-shadow: 0 0 7px #208938;
    opacity: 0;
    -webkit-animation: fadeout 4s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeout 4s; /* Firefox < 16 */
    -ms-animation: fadeout 4s; /* Internet Explorer */
    -o-animation: fadeout 4s; /* Opera < 12.1 */
    animation: fadeout 4s;
}

@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.file-status {
    color: red;
}
